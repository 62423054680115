import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  
 
 
 

  {
   path: '/', //  path: '/home',
    //name: '/home',
    component: () => import('../views/home.vue'),
       //就是管理页面的子组件 要先访问父组件才能访问下面的
       children:[
        //第一次 进user的页面 默认模块一个 父组件就要去掉名称！！！！！！！！ name不能一样！！
        {
          path: '/',
          name: 'uuserhome',
          component: ()=>import("../views/user/userhome/userhome.vue")
        },
     
        {
          path: '/userhome',
          name: 'userhome',
          component: ()=>import("../views/user/userhome/userhome.vue")
        },
       {
          path: '/usersearch',
          name: 'usersearch',
          component: ()=>import("../views/user/usersearch/usersearch.vue")
        },
        {
          path: '/house',
          name: 'house',
          component: ()=>import("../views/user/house/house.vue")
        },
        
        {
          path: '/chat',
          name: 'chat',
          component: () => import('../views/chat/chat.vue')
        },
        {
          path: '/community',
          name: 'community',
          component: () => import('../views/user/community/community.vue')
        },
        {
          path: '/userInfo',
          name: 'userInfo',
          component: () => import('../views/user/userInfo/userInfo.vue')
        }, 
        {
          path: '/upload',
          name: 'upload',
          component: () => import('../views/user/upload/upload.vue')
        },
       
      ]
  
  },

  {
    path: '/manager',
    //name: 'manager',
    component: () => import('../views/manager/manager.vue'),
    children:[
      {
        path: '/',
        name: 'mmanagerMovies',
        component: () => import('../views/manager/managerMovies/managerMovies.vue')
      },

      {
        path: '/managerMovies',
        name: 'managerMovies',
        component: () => import('../views/manager/managerMovies/managerMovies.vue')
      },
      {
        path: '/managerCommunity',
        name: 'managerCommunity',
        component: () => import('../views/manager/managerCommunity/managerCommunity.vue')
      },
      {
        path: '/managerHouse',
        name: 'managerHouse',
        component: () => import('../views/manager/managerHouse/managerHouse.vue')
      },
      {
        path: '/managerUser',
        name: 'managerUser',
        component: () => import('../views/manager/managerUser/managerUser.vue')
      },



    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
